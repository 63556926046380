<template>
    <div class="main-page">
        <section class="page-section">
            <div class="container">
                <div class="grid">
                    <div class="col-12 md:col-12 xl:col-12 sm:col-12 xs:col-12 comp-grid">
                        <div>
                            <form ref="observer" tag="form" @submit.prevent="validateForm()" @reset="resetForm">
                                <div>
                                    <table class="p-datatable-table" style="width: 100%">
                                        <tbody>
                                            <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                                                <div class="card nice-shadow-5">
                                                    <div class="grid">
                                                        <div class="col-12 md:col-12">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("employer") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlemployer"
                                                                    v-model.trim="formData.employer" :label="$t('employer')"
                                                                    type="text" :placeholder="$t('employer')" class="w-full"
                                                                    :class="getErrorClass(row, 'employer')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-12">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("position") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlposition"
                                                                    v-model.trim="formData.position" :label="$t('position')"
                                                                    type="text" :placeholder="$t('position')" class="w-full"
                                                                    :class="getErrorClass(row, 'position')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("from") }} *
                                                            </div>
                                                            <div class="">
                                                                <Dropdown class="w-full" :class="getErrorClass(row, 'from')"
                                                                    ref="ctrlfrom" v-model="formData.from"
                                                                    :options="$utils.years(formData.from, null, dob_year)"
                                                                    :label="$t('from')" :placeholder="$t('select')">
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("to") }} *
                                                            </div>
                                                            <div class="">
                                                                <Dropdown class="w-full" :class="getErrorClass(row, 'to')"
                                                                    ref="ctrlto" v-model="formData.to"
                                                                    :options="$utils.years(formData.to, null, dob_year)"
                                                                    :label="$t('to')" :placeholder="$t('select')">
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <th>
                                                    <Button
                                                        class="p-button-text p-button-danger p-button-rounded p-button-sm"
                                                        v-if="arrFormData.length > minRowCount" @click="removeFormRow(row)"
                                                        icon="pi pi-times" />
                                                </th>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="100" class="text-left">
                                                    <div class="flex justify-content-center mb-3">
                                                        <div class="">
                                                            <Button v-if="arrFormData.length < maxRowCount"
                                                                @click="addFormRow()" icon="pi pi-plus"
                                                                :label="$t('add_another_institution')"
                                                                class="p-button-info p-button-outlined p-button-sm px-3 py-2 mt-3" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </form>
                            <slot :submit="submit" :saving="saving"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "pgWorkingExperienceJobsPage",
    components: {},
    mixins: [PageMixin, AddPageMixin],
    props: {
        pageName: {
            type: String,
            default: "pgWorkingExperienceJobsPage",
        },
        maxRowCount: {
            type: Number,
            default: 4,
        },
        minRowCount: {
            type: Number,
            default: 1,
        },
        workingexperience: { type: String, default: "[]" },
        dobYear: {
            type: Number,
            default: 1950,
        },
    },
    data() {
        return {
            arrFormData: [
                {
                    employer: "",
                    position: "",
                    from: "",
                    to: "",
                },
            ],
        };
    },
    validations() {
        let arrFormData = {
            $each: helpers.forEach({
                employer: { required },
                position: { required },
                from: { required },
                to: { required },
            }),
        };
        return { arrFormData };
    },
    computed: {},
    methods: {
        validateForm() {
            this.submitted = true;
            const isFormValid = !this.v$.$invalid;
            return isFormValid;
        },
        addFormRow() {
            let newFormData = {
                employer: "",
                position: "",
                from: "",
                to: "",
            };
            this.arrFormData.push(newFormData);
        },
        removeFormRow(index) {
            this.arrFormData.splice(index, 1);
        },
        resetForm() {
            this.arrFormData = [
                {
                    employer: "",
                    position: "",
                    from: "",
                    to: "",
                },
            ];
            this.updateFormData();
        },
        getErrorClass(row, field) {
            let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
            return { "p-invalid": errors.length && this.submitted };
        },
        getWorkingExperience() {
            if (this.workingexperience) {
                let workingexperience = JSON.parse(this.workingexperience)
                this.arrFormData = workingexperience;
            }
            if (this.arrFormData.length < 1) {
                this.addFormRow();
            }
            this.dob_year = this.dobYear;
        },
    },
    watch: {
    },
    async mounted() {
        this.getWorkingExperience();
    },
    async created() { },
};
</script>
<style scoped></style>