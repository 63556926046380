<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3">
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-12">
                            <div class="">
                                <div class="flex align-items-center">
                                    <div class="ml-2">
                                        <div class="text-xl text-primary font-bold">
                                            {{ $t("working_experience") }}
                                        </div>
                                        <Divider></Divider>
                                        <div class="text-purple-500">
                                            <div class="font-bold text-yellow-500 mb-2">
                                                {{ $t("instructions") }}
                                            </div>
                                            <ul>
                                                <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section">
            <div class="container">
                <div class="grid">
                    <div class="col-12 md:col-12 sm:col-12 comp-grid">
                        <div>
                            <template v-if="!loading">
                                <div class="grid">
                                    <div class="col-12">
                                        <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                                            <!-- Tertiary Institutions Attended -->
                                            <div class="card nice-shadow-5">
                                                <div class="mb-1 text-blue-500 font-bold text-md">
                                                    {{ $t("record_of_employment") }}
                                                </div>
                                                <div class="col-12 md:col-12 p-0">
                                                    <div class="mb-3 font-bold text-sm">
                                                        {{ $t("How many years of working experience do you have?") }} *
                                                    </div>
                                                    <div class="">
                                                        <Dropdown class="w-full"
                                                            :class="getErrorClass('has_working_experience')"
                                                            ref="ctrlhas_working_experience" optionLabel="label"
                                                            optionValue="value" v-model="formData.has_working_experience"
                                                            :options="$menus.workingExperienceDurationItems"
                                                            :label="$t('has_working_experience')"
                                                            :placeholder="$t('select')">
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                                <div v-if="formData.has_working_experience && formData.has_working_experience != none"
                                                    class="mt-5 ">
                                                    <div class="mb-3 font-bold text-sm text-indigo-600">
                                                        {{ $t("Starting with the most recent employment") }} *
                                                    </div>
                                                    <div class="text-xs text-primary">{{
                                                        $t("you_can_add_up_to_4_records")
                                                    }}</div>

                                                    <div class="mt-3">
                                                        <pg-workingexperiencedata-jobs-page is-sub-page
                                                            ref="working_experienceJobsForm" :show-submit-button="false"
                                                            v-if="ready"
                                                            :workingexperience="formData.working_experience"></pg-workingexperiencedata-jobs-page>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Curriculum Vitae -->
                                            <div v-if="formData.has_working_experience && formData.has_working_experience != none"
                                                class="card nice-shadow-5">
                                                <div class="mb-1 text-indigo-700 font-bold text-md">
                                                    {{  $t("curriculum_vitae") }} *
                                                </div>
                                                <div class="grid">
                                                    <div class="col-12 md:col-12">
                                                        <div class="text-xs"></div>
                                                        <div class="text-xs text-primary">{{
                                                            $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                                                        }} .pdf </div>
                                                        <br>
                                                        <div class="mt-2">
                                                            <Uploader :auto="false" :fileLimit="1"
                                                                :class="getErrorClass('curriculum_vitae')"
                                                                :maxFileSize="10000000" accept=".pdf" :multiple="true"
                                                                style="width: 100%"
                                                                :label="$t('choose_files_or_drop_files_here')"
                                                                upload-path="s3uploader/upload/curriculum_vitae"
                                                                v-model="formData.curriculum_vitae">
                                                            </Uploader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--[form-content-end]-->
                                            <div v-if="showSubmitButton" class="text-center my-3">
                                                <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send"
                                                    :loading="saving" />
                                            </div>
                                        </form>
                                        <slot :submit="submit" :saving="saving"></slot>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width: 50px; height: 50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import pgWorkingexperiencedataJobsPage from "./pg_workingexperiencedata_jobs.vue";
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "workingexperiencedataApplicantsPage",
    components: { pgWorkingexperiencedataJobsPage },
    mixins: [PageMixin, ApplicantEditPageMixin],
    props: {
        pageName: {
            type: String,
            default: "applicants",
        },
        idName: {
            type: String,
            default: "applicant_id",
        },
        routeName: {
            type: String,
            default: "applicantsworkingexperiencedata",
        },
        pagePath: {
            type: String,
            default: "applicants/workingexperiencedata",
        },
        apiPath: {
            type: String,
            default: "applicants/workingexperiencedata",
        },
        pageIndex: { type: Number, default: 3 },
    },
    data() {
        return {
            formData: {
                has_working_experience: "",
                curriculum_vitae: "",
            },
            none: "None",
            submitted: false,
        };
    },
    computed: {
        ...mapGetters("applicants", ["workingExperienceDataRecord"]),
        pageTitle: {
            get: function () {
                return this.$t("edit_applicants");
            },
        },
        apiUrl: function () {
            if (this.id) {
                return this.apiPath + "/" + encodeURIComponent(this.id);
            }
            return this.apiPath;
        },
    },
    validations() {
        let formData = {
            has_working_experience: { required },
            curriculum_vitae: {
                required: requiredIf(() => {
                    const esit = this.formData.has_working_experience != this.none;
                    return esit;
                }),
            },
        };
        return { formData };
    },
    methods: {
        ...mapActions("applicants", ["updateWorkingExperienceData", "fetchWorkingExperienceData"]),
        load: function () {
            var url = this.apiUrl;
            this.loading = true;
            this.ready = false; // hide other components until main page is ready
            this.fetchWorkingExperienceData(url).then(
                (response) => {
                    this.loading = false;
                    this.ready = true;
                    // this.academicDataRecord = response.data;
                    this.formData = response.data;
                    this.updateFormFields();
                },
                (response) => {
                    this.resetForm();
                    this.loading = false;
                    this.showPageRequestError(response);
                }
            );
        },
        async startRecordUpdate() {
            this.submitted = true;
            const isFormValid = await this.v$.$validate();
            let payload = this.normalizedFormData();
            payload.working_experience = this.$refs.working_experienceJobsForm?.arrFormData;
            let errors = this.$refs.working_experienceJobsForm?.validateForm();

            if (this.formData.has_working_experience == this.none) {
                errors = true;
            }

            if (!isFormValid || !errors) {
                this.flashMsg(
                    this.$t("please_complete_the_form"),
                    this.$t("form_is_invalid"),
                    "error"
                );
                return;
            }

            this.saving = true;
            let id = this.id;
            let url = this.apiUrl;
            let data = { id, url, payload };
            this.updateWorkingExperienceData(data).then(
                (response) => {
                    this.saving = false;
                    this.flashMsg(
                        this.$t("information_was_updated"),
                        this.$t("form_saved_successfully")
                    );
                    this.resetForm();
                    this.closeDialogs(); // close page dialog that if opened
                    this.$emit("next", this.pageIndex);
                },
                (response) => {
                    this.saving = false;
                    this.showPageRequestError(response);
                }
            );
        },
        updateFormFields: function () {
            //update form fields value after load from api
            //e.g convert fieldvalue (value,value2,value2) to array
        },
        resetForm() {
            //reset form fields value
            this.formData = {
                curriculum_vitae: "",
                has_working_experience: "",
            };
        },
        getErrorClass(field) {
            if (this.v$.formData[field].$invalid && this.submitted) {
                return "p-invalid";
            }
            return "";
        },
    },
    watch: {},
    async mounted() {
        this.load();
    },
    async created() { },
};
</script>
<style scoped>
li {
    margin-top: 0.5em;
    line-height: 1.4em;
}
</style>
  